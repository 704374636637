import React from 'react';

class UnderConstructionPage extends React.Component {
  render() {
    return (
      <div>
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <h1>웹페이지 준비중</h1>
          <p>죄송합니다. 이 페이지는 아직 준비 중입니다. 빠른 시일 내에 완성될 예정입니다.</p>
        </div>
        <div style={{ backgroundColor: '#f0f0f0', padding: '20px', position: 'fixed', bottom: '0', left: '0', width: '100%', textAlign: 'center' }}>
          <p>문의: kwakseokhyun.dev@gmail.com | 전화: 010-8617-4626</p>
        </div>
      </div>
    );
  }
}

export default UnderConstructionPage;
